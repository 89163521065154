import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/rozcestnik/item';
import { Container } from './rozcestnik.css';

const Rozcestnik = ({ items }) => (
  <div className="rozcestnik-cont">
    <div className="rozcestnik-wrapper">
      <h3>Služby</h3>
      <Container className="rozcestnik">
        {items.map((item, i) => (
          <Item {...item} key={i} />
        ))}
      </Container>
      <div className="boxinside">
        <p>
          Společnost je současně insolvenčním správcem s povolením Ministerstva
          spravedlnosti ČR.
        </p>
      </div>
    </div>
  </div>
);

Rozcestnik.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Rozcestnik;
