import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const ItemLink = ({ title, body, link }) => (
  <Link to={link}>
    <figure>
      <figcaption>
        <strong>{title}</strong>
        {body}
      </figcaption>
    </figure>
  </Link>
);

ItemLink.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  link: PropTypes.string,
};

export default ItemLink;
