import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Title from 'components/title';
import Rozcestnik from 'components/rozcestnik';
import FullWidth from 'components/fullwidth';
import { graphql } from 'gatsby';
import obr from '../images/home-01.jpg';

const Index = ({ data }) => (
  <div className="footernoline">
    <Layout>
      <FullWidth>
        <div className="c1c1-home">
          <div className="column">
            <Title as="h2">
              Vaší firmě po&nbsp;boku. Od&nbsp;vzniku do&nbsp;zániku.
            </Title>
            <p className="perex">
              Všechno na světě vzniká, vyvíjí se a&nbsp;zaniká. My Vám pomůžeme
              s&nbsp;aplikací účetních a&nbsp;daňových předpisů, manažerským
              účetnictvím, i&nbsp;se mzdovou, sociální a&nbsp;zdravotní agendou
              tak, abyste měli dostatek prostoru pro naplnění Vašich
              podnikatelských plánů. A pokud by se nám to společně nepovedlo,
              pomůžeme Vám najít také cestu k&nbsp;novému začátku.
            </p>
          </div>
          <div className="column lp2">
            <img src={obr} alt="Čáp ilustrace" />
          </div>
        </div>
      </FullWidth>
      <Rozcestnik items={data.homeJson.rozcestnik} />
    </Layout>
  </div>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      rozcestnik {
        title
        body
        link
      }
    }
  }
`;
